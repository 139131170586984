import setAuthorizationToken from '../../utils/setAuthorizationToken';

const initState = {
  currentUser: null,
  loading: true,
  error: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    // case 'setCurrentUser':
    //   return {
    //     ...state,
    //     currentUser: action.payload?.user,
    //   };
    case 'getCurrentUser':
      return {
        ...state,
        currentUser: JSON.parse(localStorage.getItem('imiUser')),
        loading: false,
        error: null,
      };
    case 'noCurrentUser':
      return {
        ...state,
        currentUser: null,
        loading: false,
        error: null,
      };
    case 'LoginSuccess':
      // var resData = action.res.data;
      // setAuthorizationToken(action.payload?.token, action.payload?.user);
      return {
        ...state,
        currentUser: action.payload?.user,
        loading: false,
        error: null,
      };
    case 'LoginFail':
      var loginError = action.res.data.message;

      return {
        ...state,
        loading: false,
        error: loginError,
      };
    case 'LoginOnHomePageSuccess':
      // var resHomeData = action.loginData;
      // setAuthorizationToken(resHomeData.token, resHomeData.user);

      return {
        ...state,
        currentUser: action.payload?.user,
        loading: false,
        error: null,
      };
    case 'LoginOnHomePageFail':
      var loginHomeError = action.loginData.message;

      return {
        ...state,
        loading: false,
        error: loginHomeError,
      };
    case 'SessionTimeout':
      setAuthorizationToken();

      return {
        ...state,
        currentUser: null,
        loading: false,
      };
    case 'Logout':
      setAuthorizationToken();

      return {
        ...state,
        currentUser: null,
        loading: false,
      };
    default:
      return state;
  }
};
export default authReducer;
