import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {wheelLog, wheelReward, wheelSegments} from 'api/apiHelper';

export const fetchWheelSegments = createAsyncThunk('wheel/fetchSegment', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(wheelSegments({cancelToken: source.token}));
  return response.data;
});

export const fetchWheelReward = createAsyncThunk('wheel/fetchReward', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(wheelReward({uuid}, {cancelToken: source.token}));
  return response.data;
});

export const fetchWheelLog = createAsyncThunk('wheel/fetchLog', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(wheelLog({uuid}, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchWheelSegments.pending]: (state, action) => {
    state.segments = {...action.payload, loading: true};
  },
  [fetchWheelSegments.fulfilled]: (state, action) => {
    state.segments = {...action.payload, loading: false};
  },
  [fetchWheelLog.pending]: (state, action) => {
    state.log = {...action.payload, loading: true};
  },
  [fetchWheelLog.fulfilled]: (state, action) => {
    state.log = {...action.payload, loading: false};
  },
};

const name = 'wheel';

const initialState = {
  segments: {},
  log: {},
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = slice.actions;

export default slice.reducer;
