import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {useState} from 'react';

import {joinPathImage} from 'utils/imagePath.util';

import Image from 'components/Shared/Image';
import TemporaryDrawer from './Drawer';
import {PrimaryButton} from 'components/Shared/Button';

function Search() {
  return (
    <div className="relative hidden md:block pr-2 md:pr-0">
      <input
        type="text"
        id="search-navbar"
        className="block md:w-96 p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="ค้นหา..."
      />
    </div>
  );
}
export function Top() {
  const configurations = useSelector(state => get(state, 'global.mapConfiguration', {}));

  const [state, setState] = useState(false);
  const notLoginPage = window.location.pathname !== '/login';
  const toggleSearch = () => {
    setState(!state);
  };

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <Image
            src={joinPathImage(get(configurations['home.normal.logo'], 'value.data.path', ''))}
            className="h-8"
            alt="BKK IT Logo"
          />
          {/*<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">BKK IT</span>*/}
        </a>
        {notLoginPage && (
          <div className="flex items-center md:order-1">
            {state && (
              <div className="relative block pr-2">
                <input
                  type="text"
                  id="search-navbar"
                  className="block md:w-96 p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="ค้นหา..."
                />
              </div>
            )}
            <Search />

            <button
              type="button"
              data-collapse-toggle="navbar-search"
              aria-controls="navbar-search"
              aria-expanded="false"
              className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
              onClick={toggleSearch}>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>

            <TemporaryDrawer />
          </div>
        )}

        <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-2" id="navbar-search">
          {notLoginPage && (
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <PrimaryButton href="/auth/login"> เข้าสู่ระบบ</PrimaryButton>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}
