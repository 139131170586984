import App from './App';
import DateUtils from '@date-io/moment';
// import MaintenanceView from './views/errors/MaintenanceView';
import moment from 'moment';
import React from 'react';
import reportWebVitals from './reportWebVitals';
import store from './store';
import {BrowserRouter} from 'react-router-dom';
import {injectStore} from 'api/api.interceptor';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Provider} from 'react-redux';
import {render} from 'react-dom';
import './css/wickedcss.min.css';
import './index.css';
import 'moment/locale/th';
injectStore(store);

// moment.locale('th');
//
// const format = 'hh:mm:ss';
// const beforeTime = moment('01:00:00', format);
// const afterTime = moment('05:00:00', format);
// const nowTime = moment();

const rootElement = document.getElementById('root');

// if (nowTime.isBetween(beforeTime, afterTime)) {
//   render(<MaintenanceView start={beforeTime.format('HH:mm')} end={afterTime.format('HH:mm')} />, rootElement);
// } else {
render(
  <Provider store={store}>
    <MuiPickersUtilsProvider libInstance={moment} utils={DateUtils} locale={'th'}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  </Provider>,
  rootElement
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
