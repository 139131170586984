import {useEffect} from 'react';

// https://github.com/rehooks/document-title/blob/master/index.js
const useDocumentTitle = title => {
  useEffect(() => {
    document.title = `${title} | ${process.env.REACT_APP_DISPLAY_NAME} `;
  }, [title]);
};

export default useDocumentTitle;
