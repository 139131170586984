export function Footer() {
  return (
    <footer className="bottom-0 bg-gray-900">
      <div className="h-8" />

      <div className="flex justify-center items-center p-4">
        <div className="text-xs">ⓒ</div>
        <div className=" pl-2 text-sm">2024 BKK IT</div>
      </div>
    </footer>
  );
}
