import Axios from './axiosConfig';

export default function setAuthorizationToken(token, user) {
  if (token) {
    localStorage.setItem('imiToken', token);
    localStorage.setItem('imiUser', JSON.stringify(user));
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem('imiToken');
    localStorage.removeItem('imiUser');
    delete Axios.defaults.headers.Authorization;
  }
}
