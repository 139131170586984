import api from 'api/api';
import axios from 'axios';
import moment from 'moment';
import {checkInRequest, getCheckIn} from 'api/apiHelper.js';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchGetCheckIn = createAsyncThunk('getCheckIn/fetchCheckIn', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(getCheckIn({cancelToken: source.token}));
  return response.data;
});

export const fetchCheckIn = createAsyncThunk('checkIn/fetchCheckIn', async () => {
  // const state = await getState();
  // const logs = state.checkIn.data;
  const response = await api(checkInRequest());
  // list of game checkIn log
  // return [...logs, response.data.data];
  return response.data;
});

const initialState = {
  data: [],
  visible: false,
  loading: false,
  today: false,
  value: 0,
  tomorrowValue: 0,
};

const extraReducers = {
  [fetchGetCheckIn.pending]: state => {
    state.loading = true;
  },
  [fetchGetCheckIn.fulfilled]: (state, action) => {
    // const today = dayjs().date();
    const today = moment().date();
    const data = action.payload?.data;
    const passToday = data?.some(item => item.json?.day === today);
    // Today check in
    if (passToday) {
      state.today = true;
      state.visible = false; // Open dialog check in
    }
    // Today not check in
    else {
      state.today = false;
      state.visible = true;
    }

    if (data.length) {
      // Set check in user point
      state.value = data[data.length - 1].value;
    }
    state.data = data;
    state.loading = false;
  },
  [fetchCheckIn.pending]: state => {
    state.loading = true;
  },
  [fetchCheckIn.fulfilled]: (state, action) => {
    const {gameLog, gameValue, gameValueTomorrow} = action.payload.data;
    state.today = true;
    if (gameValue) {
      // latest point from gameValue
      state.value = gameValue.value;
    }
    if (gameValueTomorrow) {
      // tomorrow value point from gameValue
      state.tomorrowValue = gameValueTomorrow.value;
    }
    state.loading = false;
    state.data = [...state.data, {id: gameLog.id, game_value: gameValue}];
  },
};

const name = 'checkIn';

export const checkInSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setVisible} = checkInSlice.actions;

export default checkInSlice.reducer;
