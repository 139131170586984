import {Button, withStyles} from '@material-ui/core';

const PrimaryButton = withStyles(theme => {
  return {
    root: {
      color: theme.palette.background.black,
      background: props => !props.disabled && theme.palette.background.main,
      backgroundImage: props => !props.disabled && '-webkit-linear-gradient(top, #F5D16E, #B38220)',
      borderRadius: '20px',
      fontWeight: 'bolder',
      textDecoration: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      textTransform: 'none',
      lineHeight: 1.5,
      '&:hover': {
        background: theme.palette.background.main,
        backgroundImage: '-webkit-linear-gradient(top, #B38220, #F5D16E)',
      },
      '&:active': {
        boxShadow: 'none',
        borderColor: theme.palette.background.main,
      },
      '&:focus': {
        boxShadow: 'none',
      },
      '&:disabled': {
        background: theme.palette.background.dark,
      },
    },
  };
})(Button);

export {PrimaryButton};
