import Page from '../../../components/Page';
import Promotion from '../../shared/Promotion';
import React from 'react';
import {Container, makeStyles} from '@material-ui/core';
// import {Navigate} from 'react-router';
// import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function PromotionView() {
  const classes = useStyles();
  // const currentUser = useSelector(state => state.auth.currentUser);
  // const authLoading = useSelector(state => state.auth.loading);

  // if (!authLoading && !currentUser) return <Navigate to="/" />;

  return (
    <Page className={classes.root} title="โปรโมชั่น">
      <Container>
        <Promotion />
      </Container>
    </Page>
  );
}

export default PromotionView;
