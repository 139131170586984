import 'react-lazy-load-image-component/src/effects/opacity.css';

import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useEffect, useState} from 'react';

const {REACT_APP_CLOUDFRONT_URL} = process.env;

const LazyLoadingImage = ({width, height, enableCloudfront, src, ...rest}) => {
  const [imagePathEndpoint, setImagePathEndpoint] = useState('');

  const fetchImagePath = async () => {
    if (enableCloudfront && src) {
      setImagePathEndpoint(`${REACT_APP_CLOUDFRONT_URL}/${src}`);
    } else {
      setImagePathEndpoint(src);
    }
  };

  useEffect(() => {
    fetchImagePath();
  }, [src]);

  return (
    <div>
      {imagePathEndpoint ? (
        <LazyLoadImage
          src={imagePathEndpoint}
          width={width}
          height={height}
          effect="opacity" // Use opacity effect
          placeholderSrc="placeholder-url" // Optional placeholder
          {...rest}
        />
      ) : null}
    </div>
  );
};

export default LazyLoadingImage;
