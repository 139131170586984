import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {configurationGlobal, settingGlobal, settingOperational} from 'api/apiHelper';

export const fetchSettingGlobal = createAsyncThunk('global/fetchSettingGlobal', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(settingGlobal({cancelToken: source.token}));

  return response.data;
});

export const fetchSettingOperational = createAsyncThunk('global/fetchSettingOperational', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(settingOperational({cancelToken: source.token}));

  return response.data;
});

export const fetchConfigurationGlobal = createAsyncThunk('global/fetchConfigurationGlobal', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(configurationGlobal({cancelToken: source.token}));

  return response.data;
});
const extraReducers = {
  [fetchSettingGlobal.pending]: (state, action) => {
    state.setting = {...action.payload, loading: true};
  },
  [fetchSettingGlobal.fulfilled]: (state, action) => {
    const {data} = action.payload;
    state.setting = {...data, loading: false};
  },
  [fetchSettingOperational.pending]: (state, action) => {
    state.operation = {...action.payload, loading: true};
  },
  [fetchSettingOperational.fulfilled]: (state, action) => {
    const {data} = action.payload;
    state.operation = {...data, loading: false};
  },

  [fetchConfigurationGlobal.pending]: (state, action) => {
    state.configurations = {...action.payload, loading: true};
    state.mapConfiguration = {...action.payload, loading: true};
  },
  [fetchConfigurationGlobal.fulfilled]: (state, action) => {
    const dataList = action.payload;
    dataList?.data &&
      dataList.data.forEach(item => {
        state.mapConfiguration[item.key] = item;
      });
    state.mapConfiguration = {...state.mapConfiguration, loading: false};
    state.configurations = {...action.payload, loading: false};
  },
};

const initialState = {
  setting: {},
  operation: {},
  modal: false,
  error: {},
  configurations: [],
  mapConfiguration: {},
};

const name = 'global';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setApiError: (state, action) => {
      state.error = {...state, ...action.payload};
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {setApiError, setModal, reset} = slice.actions;

export default slice.reducer;
