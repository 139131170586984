import Container from 'components/Shared/Container';
import get from 'lodash/get';
import Page from 'components/Page';
import React, {useEffect} from 'react';
import useDocumentTitle from 'hooks/useDocumentTitle';
import {Contact} from 'components/Contact';
import {fetchSettingGlobal, reset} from 'store/global.slice';
import {makeStyles, Typography} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function ContactView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const line1Id = useSelector(state => get(state, 'global.setting.line.line1.id', ''));
  const line2Id = useSelector(state => get(state, 'global.setting.line.line2.id', ''));

  useDocumentTitle('ติดต่อเรา');

  useEffect(() => {
    const promise = dispatch(fetchSettingGlobal());

    return () => {
      promise && promise.abort();
      dispatch(reset());
    };
  }, []);

  return (
    <Page className={classes.root}>
      <Container>
        <Typography variant="h2" color="primary" className="text-center">
          ติอต่อสอบถาม
        </Typography>
        <Contact line1Id={line1Id} line2Id={line2Id} />
      </Container>
    </Page>
  );
}

export default ContactView;
