import api from '../api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {withdraw, withdrawHistory, withdrawHistoryPublic, withdrawRequest, withdrawRetry} from '../api/apiHelper';

export const fetchUserWithdrawRetry = createAsyncThunk('users/fetchWithdrawRetry', async arg => {
  const response = await api(withdrawRetry({...arg}));
  return response.data;
});

export const fetchUserWithdraw = createAsyncThunk('users/fetchWithdraw', async (arg, {getState}) => {
  const state = await getState();
  const {amount} = arg;
  const {uuid} = state.user.profile;
  const response = await api(withdraw({uuid, amount}));
  return response.data;
});

export const fetchUserWithdrawRequest = createAsyncThunk('withdraw/fetchRequest', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(withdrawRequest({uuid}, {cancelToken: source.token}));
  return response.data;
});

export const fetchWithdrawHistory = createAsyncThunk('withdraw/fetchHistory', async (arg, {getState, signal}) => {
  const state = await getState();
  const {uuid} = state.user.profile;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(withdrawHistory({uuid}, {cancelToken: source.token}));

  return response.data;
});

export const fetchWithdrawHistoryPublic = createAsyncThunk('withdraw/fetchHistoryPublic', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(withdrawHistoryPublic({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchUserWithdraw.pending]: (state, action) => {
    state.request = {...action.payload, loading: true};
  },
  [fetchUserWithdraw.fulfilled]: (state, action) => {
    state.request = {...action.payload, loading: false};
  },
  [fetchUserWithdrawRequest.pending]: (state, action) => {
    state.requestList = {...action.payload, loading: true};
  },
  [fetchUserWithdrawRequest.fulfilled]: (state, action) => {
    state.requestList = {...action.payload, loading: false};
  },
  [fetchWithdrawHistory.pending]: (state, action) => {
    state.history = {...action.payload, loading: true};
  },
  [fetchWithdrawHistory.fulfilled]: (state, action) => {
    state.history = {...action.payload, loading: false};
  },
  [fetchWithdrawHistoryPublic.pending]: (state, action) => {
    state.public = {...action.payload, loading: true};
  },
  [fetchWithdrawHistoryPublic.fulfilled]: (state, action) => {
    state.public = {...action.payload, loading: false};
  },
};

const initialState = {
  request: {},
  requestList: [],
  history: [],
  public: {},
};

export const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = withdrawSlice.actions;

export default withdrawSlice.reducer;
