import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {flipCardLogs, flipCardReward} from 'api/apiHelper';

export const fetchRandomReward = createAsyncThunk('flipCard/fetchRandomReward', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(flipCardReward({uuid, ...arg}, {cancelToken: source.token}));
  return response.data;
});

export const fetchFlipCardLog = createAsyncThunk('flipCard/fetchFlipCardLog', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(flipCardLogs({uuid, ...arg}, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchRandomReward.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchRandomReward.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchFlipCardLog.pending]: (state, action) => {
    state.log = {...action.payload, loading: true};
  },
  [fetchFlipCardLog.fulfilled]: (state, action) => {
    state.log = {...action.payload, loading: false};
  },
};

const name = 'flipCard';

const initialState = {
  data: {},
  log: {},
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
