export function register(payload) {
  return {
    method: 'POST',
    endpoint: `/api/user/register`,
    data: payload,
  };
}

export function checkPhone(payload) {
  const {value} = payload;
  return {
    method: 'GET',
    endpoint: `/api/user/checkPhone/${value}`,
  };
}

export function checkBankId(payload) {
  const {bankId} = payload;
  return {
    method: 'POST',
    endpoint: `/api/user/checkBankId/${bankId}`,
    data: payload,
  };
}

export function getInviter(payload) {
  const {afCode} = payload;
  return {
    method: 'GET',
    endpoint: `/api/user/inviter/${afCode}`,
  };
}

export function checkUsername(payload) {
  const {value} = payload;
  return {
    method: 'GET',
    endpoint: `/api/user/checkUsername/${value}`,
  };
}

export function accountName(payload) {
  return {
    method: 'POST',
    endpoint: `/api/bank/name`,
    data: payload,
  };
}
