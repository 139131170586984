import api from '../api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getMyAddress, updateMyAddress} from 'containers/Settings/ManageAddress/apiHelper';

export const fetchMyAddress = createAsyncThunk('userAddress/fetchMyAddress', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(getMyAddress({cancelToken: source.token}));
  return response.data;
});

export const fetchUpdateMyAddress = createAsyncThunk('userAddress/fetchUpdateMyAddress', async arg => {
  const response = await api(updateMyAddress(arg));
  return response.data;
});

const extraReducers = {
  [fetchMyAddress.pending]: (state, action) => {
    state.myAddress = {...action.payload, loading: true};
  },
  [fetchMyAddress.fulfilled]: (state, action) => {
    state.myAddress = {...action.payload, loading: false};
  },
  [fetchUpdateMyAddress.pending]: (state, action) => {
    state.myAddress = {...action.payload, loading: true};
  },
  [fetchUpdateMyAddress.fulfilled]: (state, action) => {
    state.myAddress = {...action.payload, loading: false};
  },
};

const name = 'userAddress';

const initialState = {
  myAddress: {},
};

export const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export default slice.reducer;
