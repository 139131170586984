import Container from '@material-ui/core/Container';
import React from 'react';

function BaseContainer(props) {
  const {children, ...rest} = props;
  return (
    <Container maxWidth="xs" {...rest}>
      {children}
    </Container>
  );
}

export default BaseContainer;
