// import navBarRoutes from './navBarRoutes';

const WEB_URL = process.env.REACT_APP_WEB_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const LINE_ID_1 = process.env.REACT_APP_LINE_ID_1;
const LINE_ID_2 = process.env.REACT_APP_LINE_ID_2;
const LINE_URL_1 = process.env.REACT_APP_LINE_URL_1;
const LINE_URL_2 = process.env.REACT_APP_LINE_URL_2;
const COLOR_THEME = process.env.REACT_APP_COLOR_THEME;
const SECONDARY_COLOR_THEME = process.env.REACT_APP_SECONDARY_COLOR_THEME;
// const envToBoolean = (envVariable) => {
//   return envVariable?.toUpperCase() === "TRUE" ? true : false;
// };

const config = {
  webUrl: WEB_URL,
  actionUrl: SERVER_URL,
  webName: CLIENT_ID,
  lineId1: LINE_ID_1,
  lineId2: LINE_ID_2,
  lineUrl1: LINE_URL_1,
  lineUrl2: LINE_URL_2,
  mainColorTheme: COLOR_THEME,
  secondaryColorTheme: SECONDARY_COLOR_THEME,
  // navBarRoutes,
};

export default config;
