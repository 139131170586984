import {browserName, BrowserTypes, browserVersion} from 'react-device-detect';

const unsupportedBrowsers = {
  [BrowserTypes.Chrome]: 79,
  [BrowserTypes.Firefox]: 80,
  [BrowserTypes.Opera]: 86,
  [BrowserTypes.Yandex]: 10,
  [BrowserTypes.Safari]: 12,
  [BrowserTypes.Ie]: 10,
  [BrowserTypes.Edge]: 15,
  [BrowserTypes.Chromium]: 120,
  [BrowserTypes.InternetExplorer]: 10,
  [BrowserTypes.MobileSafari]: 13,
  [BrowserTypes.EdgeChromium]: 115,
  [BrowserTypes.MIUI]: 12,
  [BrowserTypes.SamsungBrowser]: 20,
};

const BrowserDetect = () => {
  function isBrowserSupported(userBrowser, userVersion) {
    const mappedBrowser = unsupportedBrowsers[userBrowser];

    if (typeof mappedBrowser === 'undefined' || typeof unsupportedBrowsers[mappedBrowser] === 'undefined') {
      return true; // If the browser is not listed, assume it's supported
    }

    return userVersion > unsupportedBrowsers[mappedBrowser];
  }

  return (
    <div className="text-white text-center flex w-full justify-center items-center">
      {!isBrowserSupported(browserName, browserVersion) && (
        <div className="bg-red-800 w-full">
          <p className="text-red-200">
            ไม่รองรับ {browserName} v{browserVersion}
          </p>
        </div>
      )}
    </div>
  );
};

export default BrowserDetect;
