import config from 'configs';
import {useEffect, useState} from 'react';

import {io} from 'socket.io-client';

const useSocketOnlineUser = () => {
  const [socket, setSocket] = useState(null);

  const [online, setOnline] = useState(0);
  const [room, setRoom] = useState('');

  const handleOnlineUser = data => {
    setOnline(data);
  };

  const subscribe = () => {
    socket.on('user online', handleOnlineUser);
  };

  const disconnect = () => {
    if (socket) {
      socket.emit('leave-room', room);
      socket.off('user online', handleOnlineUser);
    }
  };

  const initialSocket = (path, room) => {
    const socketResult = io(`${config.actionUrl}/${path}`, {
      transports: ['websocket'],
      auth: {token: localStorage.getItem('token')},
    });
    socketResult.emit('join-room', room, handleOnlineUser);
    setSocket(socketResult);
    setRoom(room);
  };

  useEffect(() => {
    if (socket) {
      subscribe();
    }

    return () => {
      disconnect();
    };
  }, [socket]);

  return {initialSocket, disconnect, socket, online};
};

export default useSocketOnlineUser;
