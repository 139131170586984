import api from 'api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {gameEventList} from 'api/apiHelper';

export const fetchGameEventList = createAsyncThunk('gameEvent/fetchGameEventList', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(gameEventList({uuid, ...arg}, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchGameEventList.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchGameEventList.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
};

const name = 'gameEvent';

const initialState = {
  data: {},
  log: {},
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
