import {Footer} from './Footer';
import {Outlet} from 'react-router-dom';
import {Top} from './Top';

export function PublicLayout() {
  return (
    <div className="w-full h-screen mx-auto">
      <Top />
      <Outlet />
      <Footer />
    </div>
  );
}
