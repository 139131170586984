export const CLIENT_IDS = {
  GIMI88: 'GIMI88',
  GIMI168: 'GIMI168',
  GIMI44: 'GIMI44',
  GIMIXO: 'GIMIXO',
  GICC: 'GICC',
  GI1234: 'GI1234',
  CR77BET: 'CR77BET',
  EUROPA168: 'EUROPA168',
  FASTTOBET: 'FASTTOBET',
  GKBET888: 'GKBET888',
  PAP55: 'PAP55',
  ALFA555: 'ALFA555',
  IFINCLUB: 'IFINCLUB',
  REBOOST88: 'REBOOST88',
  AREA88BET: 'AREA88BET',
  XOBET98: 'XOBET98',
  PGLIKE: 'PGLIKE',
  SAIPAY69: 'SAIPAY69',
  CIGAR88: 'CIGAR88',
  JUMPERBET: 'JUMPERBET',
  INW88: 'INW88',
  MUMMY55: 'MUMMY55',
  BEARBET: 'GICC',
};
