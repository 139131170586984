import configs from '../configs';
// import shadows from './shadows';
import typography from './typography';
import {createTheme} from '@material-ui/core';
// #b38220
// #ffc107
const theme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#394764',
      main: configs.mainColorTheme,
      golden: '#F9D706',
      purple: '#181D32',
      lightBlue: '#03a9f4',
      dark: '#212121',
      black: '#0d0d0d',
      grey: '#454d55',
      paper: '#212121',
    },
    color: {
      gold: '#F9D706',
      white: '#ffffff',
    },
    primary: {
      main: configs.mainColorTheme,
      contrastText: '#212121',
    },
    secondary: {
      main: '#c53030', // F20024 FF5C78
    },
    success: {
      main: '#4caf50',
    },
    blue: {
      main: '#2979ff',
    },
    text: {
      main: '#fff',
      primary: '#fff',
      secondary: '#fff', //, colors.blueGrey[600],
      blue: '#2979ff',
      // disabled: '#000000',
      // hint: '#fff',
    },
  },
  // shadows,
  typography,
});

export default theme;
