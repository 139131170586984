import clsx from 'clsx';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {AppBar, Box, Button, Container, Grid, makeStyles, Toolbar, Typography} from '@material-ui/core';

const buttons = [
  {
    title: 'คาสิโน',
    href: 'casino',
  },
  {
    title: 'สล็อต',
    href: 'slot',
  },
  {
    title: 'กีฬา',
    href: 'sport',
  },
  {
    title: 'โปรโมชั่น',
    href: 'promotion',
  },
  {
    title: 'ติดต่อ',
    href: 'contact',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url("/assets/images/top/bg.jpg")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  toolbar: {
    display: 'flex',
    padding: '0 64px 0 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0 24px 0',
  },
  logo: {
    height: '96px',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuText: {
    color: theme.palette.background.dark,
    fontWeight: '700',
    whiteSpace: 'pre-wrap',
  },
  button: {
    display: 'flex',
    width: '128px',
    color: theme.palette.background.dark,
    background: '#F5D16E',
    backgroundImage: '-webkit-linear-gradient(top, #F5D16E, #B38220)',
    borderRadius: '20px',
    fontSize: '40px',
    fontWeight: 100,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: '#B38220',
      backgroundImage: '-webkit-linear-gradient(top, #B38220, #F5D16E)',
    },
  },
}));

// <Button className={classes.button} aria-haspopup="true" color="inherit" variant="contained" href="/contact">
//             <ContactIcon />
//             <Typography style={{marginLeft: 5}} variant="h5">
//               ติดต่อสอบถาม
//             </Typography>
//           </Button>

function HideOnScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({target: window ? window() : undefined});

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const TopBar = props => {
  const {className, ...rest} = props;
  const classes = useStyles();
  // const currentUser = useSelector(state => state.auth.currentUser);
  // const authLoading = useSelector(state => state.auth.loading);

  // const renderLogo = () => (
  //   <Box className={classes.logoContainer}>
  //     logo
  //     {/* <Logo className={classes.logo} /> */}
  //   </Box>
  // );

  return (
    <HideOnScroll {...props}>
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
        <Toolbar>
          <Container>
            <Grid container direction="row" className={classes.toolbar}>
              <Grid item xs={12} md={4}>
                {/* {!authLoading && !currentUser ? (
                  <RouterLink to="/">{renderLogo()}</RouterLink>
                ) : (
                  <RouterLink to="/auth/dashboard">{renderLogo()}</RouterLink>
                )} */}
              </Grid>
              <Grid item xs={12} md={8}>
                <Box className={classes.menu}>
                  {buttons.map(button => (
                    <Button variant="contained" className={classes.button} key={button.title} href={button.href}>
                      <Typography className={classes.menuText}>{button.title}</Typography>
                    </Button>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
