import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  button: {
    // width: '100%',
    // minWidth: 70,
    // minHeight: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
}));

const Menu = props => {
  const {ariaLabel, to, img, disabled, className, height, handleClose} = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    if (handleClose) {
      handleClose();
    }
    !disabled && navigate(to);
  };

  return (
    <IconButton component="span" size="small" aria-label={ariaLabel} onClick={handleClick}>
      <Button
        disabled={disabled}
        className={clsx(className, classes.button)}
        style={{backgroundImage: `url(${img})`, height}}
      />
    </IconButton>
  );
};

Menu.defaultProps = {
  to: '/',
};

export default Menu;
