import Page from '../../../components/Page';
import Promotion from '../../shared/Promotion';
import React from 'react';
import {Container, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const HomePromotion = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root} title="โปรโมชั่น">
      <Container>
        <Promotion />
      </Container>
    </Page>
  );
};

export default HomePromotion;
