import api from '../api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {depositHistory, depositHistoryPublic, transferToBank} from '../api/apiHelper';

export const fetchTransferToBank = createAsyncThunk('deposit/fetchTransferToBank', async (arg, {getState, signal}) => {
  const state = await getState();
  const {uuid, bankId, bankName} = state.user.profile.user_bank || 1;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(transferToBank({uuid, bankId, bankName}, {cancelToken: source.token}));

  return response.data;
});

export const fetchDepositHistory = createAsyncThunk('deposit/fetchHistory', async (arg, {getState, signal}) => {
  const state = await getState();
  const {uuid} = state.user.profile;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(depositHistory({uuid}, {cancelToken: source.token}));

  return response.data;
});

export const fetchDepositHistoryPublic = createAsyncThunk('deposit/fetchHistoryPublic', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(depositHistoryPublic({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchTransferToBank.pending]: (state, action) => {
    state.bank = {...action.payload, loading: true};
  },
  [fetchTransferToBank.fulfilled]: (state, action) => {
    state.bank = {...action.payload, loading: false};
  },
  [fetchDepositHistory.pending]: (state, action) => {
    state.history = {...action.payload, loading: true};
  },
  [fetchDepositHistory.fulfilled]: (state, action) => {
    state.history = {...action.payload, loading: false};
  },
  [fetchDepositHistoryPublic.pending]: (state, action) => {
    state.public = {...action.payload, loading: true};
  },
  [fetchDepositHistoryPublic.fulfilled]: (state, action) => {
    state.public = {...action.payload, loading: false};
  },
};

const name = 'deposit';
const initialState = {
  bank: {},
  history: [],
  public: [],
};
export const depositSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = depositSlice.actions;

export default depositSlice.reducer;
