import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';

import {get} from 'lodash';

import {fetchSettingGlobal, reset} from 'store/global.slice';

const LineContact = () => {
  const dispatch = useDispatch();

  const line1Id = useSelector(state => get(state, 'global.setting.line.line1.id', ''));

  useEffect(() => {
    const promise = dispatch(fetchSettingGlobal());

    return () => {
      promise && promise.abort();
      dispatch(reset());
    };
  }, []);

  const line1Url = useMemo(() => `https://line.me/R/ti/p/${line1Id}`, [line1Id]);

  return (
    line1Id && (
      <div className="fixed bottom-8 right-2 md:right-6">
        <div className="w-12 h-12 md:w-16 md:h-16 bg-green-500 rounded-full">
          <a href={line1Url} target="_blank" rel="noreferrer">
            <img src="assets/images/LINE.svg" className="rounded-full" alt="line" />
          </a>
        </div>
      </div>
    )
  );
};

export default LineContact;
