import Card from 'components/Shared/Card';
import QRCode from 'qrcode.react';
import React from 'react';
import {Typography} from '@material-ui/core';

const Contact = props => {
  const {line1Id, line2Id} = props;

  const line1Url = `https://line.me/R/ti/p/${line1Id}`;
  const line2Url = `https://line.me/R/ti/p/${line2Id}`;

  return (
    <div className="grid grid-cols-1 gap-4">
      <Card className="flex flex-col items-center space-y-2 py-4">
        <div className="text-2xl underline">หลัก</div>
        <QRCode level="M" size={150} includeMargin value={line1Url} />
        <Typography variant="h4">
          Line{' '}
          <a href={line1Url} className="text-primary">
            {line1Id}
          </a>{' '}
          (ใส่ @ ข้างหน้าด้วย)
        </Typography>
        <Typography variant="h4">
          คลิ๊ก{' '}
          <a href={line1Url}>
            <i>{line1Id}</i>
          </a>
        </Typography>
      </Card>
      <Card className="flex flex-col items-center space-y-2 py-4">
        <div className="text-2xl underline">สำรอง</div>
        <QRCode level="M" size={150} includeMargin value={line2Url} />
        <Typography variant="h4">
          Line{' '}
          <a href={line2Url} className="text-primary">
            {line2Id}
          </a>{' '}
          (ใส่ @ ข้างหน้าด้วย)
        </Typography>
        <Typography variant="h4">
          คลิ๊ก{' '}
          <a href={line2Url}>
            <i>{line2Id}</i>
          </a>
        </Typography>
      </Card>
    </div>
  );
};

export {Contact};
