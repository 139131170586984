import axios from 'axios';
import config from '../configs';
import pjson from '../../package.json';
import setAuthorizationToken from './setAuthorizationToken';
import {osName} from 'react-device-detect';

const baseURL = config.actionUrl;
const osMap = {
  Android: 'AND',
  iOS: 'IOS',
  DEFAULT: 'DSK',
};
const Axios = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    Authorization: `Bearer ${localStorage.imiToken}`,
    channel: 'web',
    webversion: pjson.version,
    platform: osMap[osName] || osMap['DEFAULT'],
  },
  // withCredentials: true
});

/*
  The below is required if you want your API to return
  server message errors. Otherwise, you'll just get
  generic status errors.

  If you use the interceptor below, then make sure you
  return an err message from your express route:

  res.status(404).json({ err: "You are not authorized to do that." })

*/
Axios.interceptors.response.use(
  response => response,
  error => {
    // console.log(error.response);
    if (
      error?.response?.status === 200 ||
      error?.response?.status === 401 ||
      error?.response?.status === 403 ||
      error?.response?.status === 500256
    ) {
      setAuthorizationToken();
      window.location = '/login';
      window.location.replace('/login');
    }
    return Promise.reject(error?.response);
  }
);

export default Axios;
