import axios from 'axios';
import setAuthorizationToken from 'utils/setAuthorizationToken';
import {setApiError, setModal} from 'store/global.slice';

let store;

export const injectStore = _store => {
  store = _store;
};

axios.interceptors.request.use(
  config => {
    // perform a task before the request is sent
    // console.log('Request was sent');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  async error => {
    store.dispatch(setApiError({message: error.message}));

    if ([401, 403].includes(error?.response?.status)) {
      setAuthorizationToken();
      store.dispatch({type: 'USER_LOGOUT'});
      window.location.replace('/auth/login');
    } else if (error.response.status === 503) {
      store.dispatch(setModal(true));
    } else if (error.response.status === 408 || error.code === 'ECONNABORTED') {
      // console.log(`A timeout happen on url ${error.config.url}`);
      store.dispatch(setModal(true));
    } else if (axios.isCancel(error)) {
      // console.log('axios request cancelled');
      store.dispatch(setModal(true));
    }

    return Promise.reject(error);
  }
);
