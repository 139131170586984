import React from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';

import LazyLoadingImage from './Shared/Image/LazyLoadingImage';

const Logo = props => {
  const configurations = useSelector(state => get(state, 'global.mapConfiguration', {}));

  return (
    <LazyLoadingImage
      enableCloudfront
      src={get(configurations['home.normal.logo'], 'value.data.path', '')}
      alt={process.env.REACT_APP_DISPLAY_NAME}
      {...props}
    />
    // <img
    //   src={joinPathImage(get(configurations['home.normal.logo'], 'value.data.path', ''))}
    //   alt={process.env.REACT_APP_DISPLAY_NAME}
    //   {...props}
    // />
  );
};

export default Logo;
