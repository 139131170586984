import {Paper, withStyles} from '@material-ui/core';

const BaseCard = withStyles(theme => {
  return {
    root: {
      backgroundColor: props => (props.color ? props.color : theme.palette.background.black),
      borderRadius: 15,
      borderWidth: 1,
      borderColor: props => (props.bordercolor ? props.bordercolor : theme.palette.background.main),
      borderStyle: 'solid',
      minHeight: 54,
    },
  };
})(Paper);

export default BaseCard;
