import PropTypes from 'prop-types';
import {get} from 'lodash';
import {useSelector} from 'react-redux';

import React, {forwardRef, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

const {REACT_APP_CLOUDFRONT_URL} = process.env;

const Page = forwardRef(({children, title = '', ...rest}, ref) => {
  const configurations = useSelector(state => get(state, 'global.mapConfiguration', {}));

  const backgroundPathEndpoint = get(configurations['home.normal.background'], 'value.data.path', '');

  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

  useEffect(() => {
    if (backgroundPathEndpoint) {
      setBackgroundImageUrl(`${REACT_APP_CLOUDFRONT_URL}/${backgroundPathEndpoint}`);
    }
  }, [backgroundPathEndpoint]);

  return (
    <div
      ref={ref}
      {...rest}
      style={{
        backgroundImage: `url("${backgroundImageUrl}")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        // backgroundColor: '#161A26',
        // paddingLeft: '150px',
        // paddingRight: '150px',
      }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Page.displayName = 'Page';

export default Page;
