import Page from '../../../components/Page';
import React from 'react';
import {Box, CardMedia, Container, Grid, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  goldenTextColor: {
    color: theme.palette.background.golden,
  },
  textColor: {
    color: 'white',
  },
  headerColor: {
    backgroundColor: theme.palette.background.black,
  },
  containerColor: {
    backgroundColor: theme.palette.background.dark,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.background.blue,
  },
  boxColor: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.background.purple,
  },
}));

function TableScore() {
  const classes = useStyles();

  return (
    <>
      <Page className={classes.root} title="ติดต่อสอบถาม">
        <Container>
          <Box boxShadow={3} p={2} className={classes.containerColor} borderRadius={30} style={{marginBottom: 10}}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              style={{marginBottom: 10}}>
              <Grid item>
                <Typography variant="h2" className={classes.goldenTextColor}>
                  ตารางคะแนน
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
              <Grid xs={12} item>
                <Box borderRadius={30} display="center" alignItems="center" className={classes.boxColor}>
                  <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} item>
                    <Grid
                      xs={12}
                      style={{
                        width: '100%',
                      }}
                      item>
                      <CardMedia
                        height="520px"
                        component="iframe"
                        frameBorder="0"
                        allowFullScreen="allowfullscreen"
                        src="https://www.score108.com/soccer/standingAPI"
                        title="ตารางคะแนน"></CardMedia>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  );
}

export default TableScore;
