import clsx from 'clsx';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
  },
}));

const Image = props => {
  const classes = useStyles();
  const {src, alt, onClick, className, ...rest} = props;
  const handleError = e => {
    e.target.onerror = null;
    e.target.src = '/assets/images/notfound.png';
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <img
      src={src}
      alt={alt}
      loading="lazy"
      onClick={handleClick}
      onError={handleError}
      className={clsx(classes.root, className)}
      {...rest}
    />
  );
};

export default Image;
