import Page from '../../components/Page';
import React from 'react';
import {Box, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  goldenTextColor: {
    color: '#ffc107',
  },
  whiteTextColor: {
    color: 'white',
  },
  containerColor: {
    backgroundColor: '#282f44',
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
  },
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Container>
        <Box boxShadow={3} p={4} className={classes.containerColor} borderRadius={15} style={{marginBottom: 10}}>
          <Typography align="center" className={classes.goldenTextColor} variant="h1" gutterBottom>
            404
          </Typography>
          <Typography align="center" className={classes.goldenTextColor} variant="h2" gutterBottom>
            ไม่พบหน้าที่คุณต้องการ
          </Typography>
          <Grid container direction="column" justifyContent="center" alignItems="center" style={{marginTop: 10}}>
            <Link to="/auth/dashboard">
              <span className="text-white text-xl">
                <i>กลับสู่หน้าหลัก</i>
              </span>
            </Link>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
