import api from '../api/api';
import axios from 'axios';
import {affBonus, affCode, affDownline, affDownlineBonus, affWithdraw} from '../api/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {isEmpty} from 'lodash';

export const fetchAffiliateCode = createAsyncThunk('affiliate/fetchCode', async (arg, {getState, signal}) => {
  const state = await getState();
  const uuid = state.user.profile.uuid;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(affCode({uuid}, {cancelToken: source.token}));
  return response.data;
});

export const fetchAffiliateBalance = createAsyncThunk('affiliate/fetchBalance', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(affBonus({username}, {cancelToken: source.token}));
  return response.data;
});

export const fetchAffiliateDownline = createAsyncThunk('affiliate/fetchDownline', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(affDownline({username}, {cancelToken: source.token}));
  return response.data;
});

/** Withdraw af bonus and lost bonus */
export const fetchAffiliateWithdraw = createAsyncThunk('affiliate/fetchWithdraw', async (arg, {getState}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const response = await api(affWithdraw({...arg, username}));
  return response.data;
});

export const fetchAffiliateDownlineBonus = createAsyncThunk(
  'affiliate/fetchDownlineBonus',
  async (arg, {getState, signal}) => {
    const state = await getState();
    const username = state.user.profile.user_pass.username;
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    // const response = require('../apiMock/affiliate/downline-bonus.json');
    const response = await api(affDownlineBonus({...arg, username}, {cancelToken: source.token}));
    return response.data;
  }
);

const extraReducers = {
  [fetchAffiliateCode.pending]: (state, action) => {
    state.code = {...action.payload, loading: true};
  },
  [fetchAffiliateCode.fulfilled]: (state, action) => {
    state.code = {...action.payload, loading: false};
  },
  [fetchAffiliateBalance.pending]: (state, action) => {
    state.balance = {...action.payload, loading: true};
  },
  [fetchAffiliateBalance.fulfilled]: (state, action) => {
    state.balance = {...action.payload, loading: false};
  },
  [fetchAffiliateDownline.pending]: (state, action) => {
    state.downline = {...action.payload, loading: true};
  },
  [fetchAffiliateDownline.fulfilled]: (state, action) => {
    state.downline = {...action.payload, loading: false};
  },
  [fetchAffiliateWithdraw.pending]: (state, action) => {
    state.withdraw = {...action.payload, loading: true};
  },
  [fetchAffiliateWithdraw.fulfilled]: (state, action) => {
    state.withdraw = {...action.payload, loading: false};
  },
  [fetchAffiliateDownlineBonus.pending]: (state, action) => {
    state.downlineBonus = {...action.payload, loading: true};
  },
  [fetchAffiliateDownlineBonus.fulfilled]: (state, action) => {
    const {
      success,
      data: {downline_users, total_bonus},
    } = action.payload;
    const users =
      !isEmpty(downline_users) &&
      Object.entries(downline_users).map(obj => {
        const key = obj[0];
        const value = obj[1];
        return {username: key, ...value};
      });

    state.downlineBonus = {success, data: {total_bonus, downline_users: users}, loading: false};
  },
};

const initialState = {
  code: {},
  balance: {},
  downline: {},
  withdraw: {},
  downlineBonus: {},
};

const name = 'affiliate';

export const affiliateSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setBalance: state => {
      state.balance = {};
    },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setBalance} = affiliateSlice.actions;

export default affiliateSlice.reducer;
