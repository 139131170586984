import {useEffect, useState} from 'react';

const NetworkConnectivity = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className="text-white text-center flex w-full justify-center items-center">
      {!isOnline && (
        <div className="bg-red-800 w-full">
          <p className="text-red-200">ไม่มีอินเทอร์เน็ต</p>
        </div>
      )}
    </div>
  );
};

export default NetworkConnectivity;
