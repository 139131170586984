import Menu from 'components/Shared/Menu';
import nav from 'configs/nav.config';
import {CLIENT_IDS} from 'constants/clients.constant';

const whitelist = [CLIENT_IDS.BEARBET];

const {REACT_APP_CLIENT_ID: CLIENT_ID} = process.env;

const WithdrawBottom = () => {
  if (whitelist.includes(CLIENT_ID)) {
    return <Menu edge="end" ariaLabel={nav.WITHDRAW.TITLE} to={nav.WITHDRAW.TO} img={nav.WITHDRAW.IMG} height={70} />;
  } else {
    return <Menu edge="end" ariaLabel={nav.MEDIA.TITLE} to={nav.MEDIA.TO} img={nav.MEDIA.IMG} height={70} />;
  }
};

export default WithdrawBottom;
