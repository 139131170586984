import BrowserDetect from './BrowserDetect';
import clsx from 'clsx';
import Logo from 'components/Logo';
import NetworkConnectivity from 'layouts/DashboardLayout/NetworkConnectivity';
import popup from 'utils/popup.util';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import useSocketOnlineUser from 'hooks/useSocketOnlineUser';
import {AppBar, Button, Container, IconButton, makeStyles, Toolbar} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {signOut} from 'store/actions/authActions';
import {useDispatch, useSelector} from 'react-redux';

const {REACT_APP_TAWK_PROPERTY_ID} = process.env;

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `2px solid ${theme.palette.background.main}`,
    backgroundColor: theme.palette.background.black,
    // height: 64,
    // backgroundImage: `url("/assets/images/top/bg.jpg")`,
    // backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '100% 100%',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  toolbar: {
    padding: 0,
  },
  logoutButton: {
    minWidth: 32,
    height: 32,
    backgroundImage: `url("/assets/images/top/150x150-logout.png")`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  logo: {
    height: 48,
  },
}));

// onMobileNavOpen
const TopBar = function (props) {
  const {className, tawkHideWidget, ...rest} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth?.currentUser);
  // const userBalance = useSelector((state) => state.user.userBalance);
  const {initialSocket} = useSocketOnlineUser();

  useEffect(() => {
    initialSocket('user', 'allUserRoom');
  }, []);

  const handleLogout = () => {
    popup({
      title: 'ยืนยันออกจากระบบ',
      text: 'คุณต้องการออกจากระบบใช่หรือไม่?',
      icon: 'question',
      timer: 5000,
      // background: '#212121',
      showCancelButton: true,
      // cancelButtonColor: '#FF5C78',
      confirmButtonText: 'ออกจากระบบ',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        if (REACT_APP_TAWK_PROPERTY_ID) {
          tawkHideWidget();
        }
        signOut(dispatch);
        popup({
          title: 'ออกจากระบบสำเร็จ',
          timer: 2000,
          background: '#212121',
          customClass: {
            title: 'text-white',
            confirmButton: 'text-black bg-primary',
          },
        });
      }
    });
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <NetworkConnectivity />
      <BrowserDetect />
      <Container maxWidth="lg">
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" component="span" size="small" aria-label="home">
            <RouterLink to="/auth/dashboard">
              <Logo className={classes.logo} />
            </RouterLink>
          </IconButton>

          <div className="flex-grow" />
          <div className="flex">
            <div className="flex flex-col justify-center px-2">
              <p className="text-lg leading-none text-white font-base truncate">{currentUser?.name}</p>
              <p className="text-white text-lg font-base leading-none">{currentUser?.user_pass?.username}</p>
            </div>
            <div className="flex justify-center items-center">
              <IconButton edge="end" component="span" size="small" aria-label="logout" onClick={handleLogout}>
                <Button className={classes.logoutButton} />
              </IconButton>
            </div>
          </div>
          {/*<Hidden xsDown>*/}
          {/*  <Button*/}
          {/*    className={classes.button}*/}
          {/*    aria-haspopup="true"*/}
          {/*    color="inherit"*/}
          {/*    variant="contained"*/}
          {/*    onClick={onSignOut}*/}
          {/*    startIcon={<PersonIcon />}>*/}
          {/*    <Typography variant="h5">{currentUser?.username}</Typography>*/}
          {/*  </Button>*/}
          {/*</Hidden>*/}
          {/*<Hidden smUp>*/}
          {/*  <IconButton color="inherit" onClick={onMobileNavOpen}>*/}
          {/*    <MenuIcon style={{color: 'white'}} />*/}
          {/*  </IconButton>*/}
          {/*</Hidden>*/}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
