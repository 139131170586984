import api from '../api/api';
import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {rewardList, rewardRequest, rewardRequestHistory} from '../api/apiHelper';

export const fetchRewardList = createAsyncThunk('reward/fetchList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(rewardList({...arg}, {cancelToken: source.token}));
  return response.data;
});

export const fetchRewardRequest = createAsyncThunk('reward/fetchRequest', async (arg, {getState}) => {
  const state = await getState();
  const userUuid = state.user.profile.uuid;
  const response = await api(rewardRequest({...arg, userUuid}));
  return response.data;
});

export const fetchRewardRequestHistory = createAsyncThunk(
  'reward/fetchRequestHistory',
  async (arg, {getState, signal}) => {
    const state = await getState();
    const userUuid = state.user.profile.uuid;
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await api(rewardRequestHistory({userUuid}, {cancelToken: source.token}));
    return response.data;
  }
);

const extraReducers = {
  [fetchRewardList.pending]: (state, {payload, meta: {arg}}) => {
    state.category = {
      ...state.category,
      [arg?.categoryId]: {
        ...payload,
      },
      loading: true,
    };
  },
  [fetchRewardList.fulfilled]: (state, {payload, meta: {arg}}) => {
    state.category = {
      ...state.category,
      [arg?.categoryId]: {
        ...payload,
      },
      loading: false,
    };
  },
  [fetchRewardRequest.pending]: (state, action) => {
    state.request = {...action.payload, loading: true};
  },
  [fetchRewardRequest.fulfilled]: (state, action) => {
    state.request = {...action.payload, loading: false};
  },
  [fetchRewardRequestHistory.pending]: (state, action) => {
    state.history = {...action.payload, loading: true};
  },
  [fetchRewardRequestHistory.fulfilled]: (state, action) => {
    state.history = {...action.payload, loading: false};
  },
};

const name = 'reward';

const initialState = {
  category: {},
  request: {},
  history: {},
};

export const rewardSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = rewardSlice.actions;

export default rewardSlice.reducer;
