import React from 'react';

const Footer = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="text-xs">ⓒ</div>
      <div className="text-white pl-2 text-sm">2022 {process.env.REACT_APP_DISPLAY_NAME}</div>
    </div>
  );
};

export default Footer;
