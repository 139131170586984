export function login(payload) {
  return {
    method: 'POST',
    endpoint: '/api/auth/login',
    data: payload,
  };
}
/** Global */
export function settingGlobal({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/setting',
    cancelToken,
  };
}

export function settingOperational({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/setting/operational/website',
    cancelToken,
  };
}

export function configurationGlobal({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/configuration',
    cancelToken,
  };
}

/** Configuration */
export function configurationList({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/auth/configuration/list',
    cancelToken,
  };
}

export function configurationByKey(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/auth/configuration/key',
    cancelToken,
    params: {
      key: payload.key,
    },
  };
}

/** User */
export function detail(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/user/detail/${uuid}`,
    cancelToken,
  };
}

export function point(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/user/${uuid}`,
    cancelToken,
  };
}

export function credit(payload, {cancelToken}) {
  const {username, balanceType} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/user/getCredit/${username}`,
    cancelToken,
    data: {balanceType},
  };
}

export function cashOut(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/user/game/cashOut`,
    cancelToken,
    data: payload,
  };
}

export function openGame(payload) {
  return {
    method: 'POST',
    endpoint: '/api/auth/user/opengame',
    data: payload,
  };
}

export function transferToBank(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/user/transfer/to/bank`,
    data: payload,
    cancelToken,
  };
}

export function gameList(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/user/game/list`,
    cancelToken,
    data: payload,
  };
}

export function games(payload, {cancelToken}) {
  return {
    endpoint: `/api/auth/user/games/${payload.gameCate}`,
    cancelToken,
    data: payload,
  };
}

export function changePassword(payload) {
  const {uuid, oldPassword, newPassword, confirmPassword} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/user/change-password/${uuid}`,
    data: {oldPassword, newPassword, confirmPassword},
  };
}

export function level(payload) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/user/level/${uuid}`,
  };
}

/** Deposit */
export function depositHistory(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/deposit/${uuid}`,
    cancelToken,
  };
}

export function depositHistoryPublic({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/api/deposit`,
    cancelToken,
  };
}

/** Withdraw */
export function withdrawHistory(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/withdraw/withdraw-history/${uuid}`,
    cancelToken,
  };
}

export function withdrawHistoryPublic({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/withdraw/history',
    cancelToken,
  };
}

export function withdrawRetry(payload) {
  // const {uuid, amount, userId} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/withdraw/retry`,
    data: payload,
  };
}

export function withdraw(payload) {
  const {uuid, amount} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/withdraw/${uuid}`,
    data: {amount},
  };
}

export function withdrawRequest(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/withdraw/withdraw-request/${uuid}`,
    cancelToken,
  };
}

/** Other */

export function setting(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: '/api/auth/setting',
    cancelToken,
    data: payload,
  };
}

/** AF */
export function affBonus(payload, {cancelToken}) {
  const {username} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/affiliate/bonus/${username}`,
    cancelToken,
  };
}

export function affDownline(payload, {cancelToken}) {
  const {username} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/affiliate/downline/${username}`,
    cancelToken,
  };
}

export function affCode(payload, {cancelToken}) {
  const {uuid} = payload;
  return {
    method: 'GET',
    endpoint: `/api/auth/affiliate/code/${uuid}`,
    cancelToken,
  };
}

export function affWithdraw(payload) {
  const {username, amount, type} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/affiliate/withdraw/${username}`,
    data: {amount, type},
  };
}

export function affDownlineBonus(payload, {cancelToken}) {
  const {username, start, end} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/affiliate/downline/bonus/${username}`,
    data: {start, end},
    cancelToken,
  };
}

/** Log */
export function affLog(payload, {cancelToken}) {
  const {username, type, start, end} = payload;
  return {
    method: 'POST',
    endpoint: `/api/auth/log/affiliate/${username}`,
    data: {type, start, end},
    cancelToken,
  };
}

/** Reward */
export function rewardList(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/reward`,
    data: payload,
    cancelToken,
  };
}

export function rewardRequest(payload) {
  return {
    method: 'POST',
    endpoint: `/api/auth/reward/request`,
    data: payload,
  };
}

export function rewardRequestHistory(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/reward/request/history`,
    data: payload,
    cancelToken,
  };
}

export function wheelSegments({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/api/auth/wheel`,
    cancelToken,
  };
}

export function wheelReward(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/wheel/reward`,
    data: payload,
    cancelToken,
  };
}

export function wheelLog(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/wheel/log`,
    data: payload,
    cancelToken,
  };
}

export function flipCardReward(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: `/api/auth/game/flip-card/random-reward`,
    data: payload,
    cancelToken,
  };
}
export function flipCardLogs(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/api/auth/game/flip-card/logs`,
    params: payload,
    cancelToken,
  };
}

export function gameEventList(payload, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/api/auth/game`,
    cancelToken,
  };
}

export function getCheckIn({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/api/auth/game/check-in`,
    cancelToken,
  };
}

export function checkInRequest() {
  return {
    method: 'POST',
    endpoint: `/api/auth/game/check-in`,
    data: {},
  };
}

// * Slip
export function base64VerifySlip(payload) {
  return {
    method: 'POST',
    endpoint: '/api/auth/slip/verify',
    data: payload,
  };
}

export function depositWithSlipManual(payload) {
  return {
    method: 'POST',
    endpoint: '/api/auth/slip/confirm',
    data: payload,
  };
}

/**
 *
 * @param {FormData} payload
 * @param {file} payload.file
 * @returns
 */
export function requestToDepositWithSlip(payload, {cancelToken}) {
  return {
    method: 'POST',
    endpoint: '/api/auth/slip/request-deposit',
    data: payload,
    cancelToken,
  };
}

export function getMyRequestToDepositWithSlip({cancelToken}) {
  return {
    method: 'GET',
    endpoint: '/api/auth/slip/my/request',
    cancelToken,
  };
}
