import api from '../api/api';
import axios from 'axios';
import {AF_TYPE} from '../constants/affiliate.contant';
import {affLog} from '../api/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchAffiliateLog = createAsyncThunk('log/fetchAffiliate', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(affLog({...arg, type: AF_TYPE.AF, username}, {cancelToken: source.token}));
  return response.data;
});

export const fetchWinLostLog = createAsyncThunk('log/fetchWinLost', async (arg, {getState, signal}) => {
  const state = await getState();
  const username = state.user.profile.user_pass.username;
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(affLog({...arg, type: AF_TYPE.WL, username}, {cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchAffiliateLog.pending]: (state, action) => {
    state.affiliate = {...action.payload, loading: true};
  },
  [fetchAffiliateLog.fulfilled]: (state, action) => {
    state.affiliate = {...action.payload, loading: false};
  },
  [fetchWinLostLog.pending]: (state, action) => {
    state.winlost = {...action.payload, loading: true};
  },
  [fetchWinLostLog.fulfilled]: (state, action) => {
    state.winlost = {...action.payload, loading: false};
  },
};

const name = 'log';

const initialState = {
  affiliate: {},
  winlost: {},
  reward: {},
};

export const logSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = logSlice.actions;

export default logSlice.reducer;
