import Axios from '../../utils/axiosConfig';
// import md5 from "md5";

export const getCurrentUser = dispatch => {
  if (localStorage.imiToken && localStorage.imiUser) {
    dispatch({type: 'getCurrentUser'});
  } else {
    dispatch({type: 'noCurrentUser'});
  }
};

export const signIn = (dispatch, username, password) => {
  Axios.post('/auth/login', {
    username: username,
    password: password,
  }).then(res => {
    if (res.data.token) {
      dispatch({type: 'LoginSuccess', res});
    } else {
      dispatch({type: 'LoginFail', res});
    }
  });
};

export const signInOnHomePage = (dispatch, username, password) => {
  return Axios.post('/auth/login', {
    username: username,
    password: password,
  }).then(res => res.data);
};

export const signOut = dispatch => {
  dispatch({type: 'LOGOUT'});
};

export const register = (values, marketing) => {
  var body = {
    ...values,
    marketing: marketing || null,
  };
  return Axios.post('/user/register', body).then(res => res.data);
};

// export const register = (dispatch, values) => {
//   var body = { ...values };
//   Axios.post("/user/register", body).then((res) => {
//     if (res.data.success) {
//       dispatch({ type: "RegisterSuccess", res });
//     }
//     alert(res.data.message);
//   });
// };

// export const signOut = (dispatch) => {
//   Axios.get("/auth/user/logout")
//     .then((res) => {
//       dispatch({ type: "Logout" });
//     })
//     .catch(() => dispatch({ type: "Logout" }));
// };
