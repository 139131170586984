import {createSlice} from '@reduxjs/toolkit';
import {fetchAffiliateWithdraw} from './affiliate.slice';

const extraReducers = {
  [fetchAffiliateWithdraw.pending]: (state, action) => {
    state.withdraw = {...action.payload, loading: true};
  },
  [fetchAffiliateWithdraw.fulfilled]: (state, action) => {
    state.withdraw = {...action.payload, loading: false};
  },
};

const name = 'winlost';

const initialState = {
  withdraw: {},
};

export const winlostSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset} = winlostSlice.actions;

export default winlostSlice.reducer;
