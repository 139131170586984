import axios from 'axios';

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import api from 'api/api';
import {
  base64VerifySlip,
  depositWithSlipManual,
  getMyRequestToDepositWithSlip,
  requestToDepositWithSlip,
} from 'api/apiHelper';

export const fetchRequestToDepositWithSlip = createAsyncThunk(
  'slip/fetchRequestToDepositWithSlip',
  async (arg, {signal, rejectWithValue}) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await api(requestToDepositWithSlip(arg, {cancelToken: source.token}));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchMySlipRequest = createAsyncThunk(
  'slip/fetchMySlipRequest',
  async (arg, {signal, rejectWithValue}) => {
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await api(getMyRequestToDepositWithSlip({cancelToken: source.token}));
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchBase64VerifySlip = createAsyncThunk('slip/fetchVerifySlip', async (arg, {rejectWithValue}) => {
  try {
    const response = await api(base64VerifySlip(arg));
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchDepositWithSlipManual = createAsyncThunk('slip/fetchDepositWithSlipManual', async arg => {
  const response = await api(depositWithSlipManual(arg));
  return response.data;
});

const extraReducers = {
  [fetchMySlipRequest.pending]: (state, action) => {
    state.mySlipRequest = {...action.payload, loading: true};
  },
  [fetchMySlipRequest.fulfilled]: (state, action) => {
    state.mySlipRequest = {...action.payload, loading: false};
  },
  [fetchMySlipRequest.rejected]: (state, action) => {
    state.mySlipRequest = {loading: false, error: action.payload.response.data.message};
  },

  [fetchRequestToDepositWithSlip.rejected]: (state, action) => {
    state.error = {loading: false, error: action};
  },

  [fetchBase64VerifySlip.pending]: (state, action) => {
    state.data = {...action.payload, loading: true};
  },
  [fetchBase64VerifySlip.fulfilled]: (state, action) => {
    state.data = {...action.payload, loading: false};
  },
  [fetchBase64VerifySlip.rejected]: (state, action) => {
    state.data = {loading: false, error: action.payload.response.data.message};
  },
  [fetchDepositWithSlipManual.pending]: (state, action) => {
    state.result = {...action.payload, loading: true};
  },
  [fetchDepositWithSlipManual.fulfilled]: (state, action) => {
    state.result = {...action.payload, loading: false};
  },
};

const name = 'slip';

const initialState = {
  data: {},
  result: {},
  error: {},
  mySlipRequest: {},
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => {
      return {data: {}};
    },
  },
  extraReducers,
});

export const {reset} = slice.actions;

export default slice.reducer;
