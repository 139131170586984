import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const popup = content => {
  return MySwal.fire({
    icon: 'question',
    confirmButtonText: 'ตกลง',
    timer: 5000,
    background: '#212121',
    reverseButtons: true,
    showCloseButton: true,
    customClass: {
      popup: 'swal2-content',
      title: 'swal2-title',
    },
    ...content,
  });
};

export default popup;
