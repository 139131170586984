import axios from 'axios';
import config from '../configs';
import pjson from '../../package.json';
// import setAuthorizationToken from 'utils/setAuthorizationToken';
import {osName} from 'react-device-detect';

const baseURL = config.actionUrl;
const osMap = {
  Android: 'AND',
  iOS: 'IOS',
  DEFAULT: 'DSK',
};

const defaultOptions = {
  method: 'GET',
  baseURL,
  endpoint: '',
  data: {},
  params: {},
  timeout: 60000,
  responseType: 'json',
  headers: {
    platform: osMap[osName] || osMap['DEFAULT'],
    channel: 'web',
    webversion: pjson.version,
    webhostname: window.location.hostname,
  },
};

// const Axios = axios.create({
//   baseURL,
//   timeout: 60000,
//   headers: {
//     Authorization: `Bearer ${localStorage.imiToken}`,
//     channel: 'web',
//     webversion: pjson.version,
//     platform: osMap[osName] || osMap['DEFAULT'],
//   },
//   // withCredentials: true
// });

function addDefaultApiConfig(apiOptions) {
  const endpoint = apiOptions.endpoint || defaultOptions.endpoint;

  const headers = {
    Authorization: `Bearer ${localStorage.getItem('imiToken')}`,
    ...defaultOptions.headers,
    ...apiOptions.headers,
  };

  return {
    ...defaultOptions,
    ...apiOptions,
    headers,
    url: endpoint,
  };
}

const api = apiOptions => {
  const apiConfig = addDefaultApiConfig(apiOptions);
  return axios(apiConfig);
};

export default api;
