import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import {FcSalesPerformance} from 'react-icons/fc';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
const useStyles = makeStyles(theme => ({
  goldenTextColor: {
    color: theme.palette.background.golden,
  },
  textColor: {
    color: 'white',
  },
  containerColor: {
    backgroundColor: theme.palette.background.dark,
  },
  boxColor: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: theme.palette.background.purple,
  },
}));

const Promotion = () => {
  const classes = useStyles();
  const settingDetail = useSelector(state => get(state, 'user.setting', ''));
  const {bonusTimeStart2, bonusTimeEnd2} = settingDetail || '';

  return (
    <Box boxShadow={3} p={2} className={classes.containerColor} borderRadius={30} style={{marginBottom: 10}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{marginBottom: 10}}>
        <Grid item>
          <Typography variant="h2" className={classes.goldenTextColor}>
            โปรโมชั่น
          </Typography>
        </Grid>
      </Grid>
      {settingDetail?.bonusMax > 0 ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid md={6} item>
            <Box borderRadius={30} display="center" alignItems="center" className={classes.boxColor}>
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} item>
                <Grid item>
                  <Typography variant="h3" color="secondary">
                    ช่วงที่ 1
                  </Typography>
                </Grid>
                <Grid item>
                  <FcSalesPerformance style={{fontSize: 100}} />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    รับโบนัส {settingDetail?.bonusRate}% ฝากเครดิตช่วงเวลา
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.goldenTextColor}>
                    {settingDetail?.bonusTimeStart?.substring(0, 5)} - {settingDetail?.bonusTimeEnd?.substring(0, 5)} น.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    และโอนเงินยอดลงท้ายด้วย {(settingDetail?.endNumberBonus / 100).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" className={classes.textColor}>
                    รับสูงสุด {settingDetail?.bonusMax} บาท
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid md={6} item>
            <Box borderRadius={30} display="center" alignItems="center" className={classes.boxColor}>
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} item>
                <Grid item>
                  <Typography variant="h3" color="secondary">
                    ช่วงที่ 2
                  </Typography>
                </Grid>
                <Grid item>
                  <FcSalesPerformance style={{fontSize: 100}} />
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    รับโบนัส {settingDetail?.bonusRate2}% ฝากเครดิตช่วงเวลา
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.goldenTextColor}>
                    {bonusTimeStart2?.substring(0, 5)} - {bonusTimeEnd2?.substring(0, 5)} น.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" color="primary">
                    และโอนเงินยอดลงท้ายด้วย {(settingDetail?.endNumberBonus2 / 100).toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" className={classes.textColor}>
                    รับสูงสุด {settingDetail?.bonusMax} บาท
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{marginBottom: 10}}>
          <Grid item>
            <Typography variant="h4" className={classes.textColor}>
              ยังไม่มีโปรโมชั่นในขณะนี้
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Promotion;
