import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import api from 'api/api';
import {configurationByKey, configurationList} from 'api/apiHelper';

export const fetchConfigurationList = createAsyncThunk(
  'configuration/fetchConfigurationList',
  async (arg, {signal}) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const response = await api(configurationList({cancelToken: source.token}));

    return response.data;
  }
);

export const fetchConfigurationByKey = createAsyncThunk(
  'configuration/fetchConfigurationByKey',
  async (arg, {signal}) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const response = await api(configurationByKey(arg, {cancelToken: source.token}));

    return response.data;
  }
);

const extraReducers = {
  [fetchConfigurationList.pending]: (state, action) => {
    state.configurations = {...action.payload, loading: true};
    state.mapConfiguration = {...action.payload, loading: true};
  },
  [fetchConfigurationList.fulfilled]: (state, action) => {
    const dataList = action.payload;
    dataList?.data &&
      dataList.data.forEach(item => {
        state.mapConfiguration[item.key] = item;
      });
    state.mapConfiguration = {...state.mapConfiguration, loading: false};
    state.configurations = {...action.payload, loading: false};
  },
  [fetchConfigurationByKey.pending]: (state, action) => {
    state.configurations = {...action.payload, loading: true};
  },
  [fetchConfigurationByKey.fulfilled]: (state, action) => {
    state.configurations = {...action.payload, loading: false};
  },
};

const initialState = {
  configurations: [],
  configuration: {},
  mapConfiguration: {},
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers,
});

export const {reset} = configurationSlice.actions;

export default configurationSlice.reducer;
